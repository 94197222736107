<template>
  <div>产品目录</div>
</template>

<script>
export default {
  name: 'productList',
  data () {
    return {}
  }
}
</script>

<style>
</style>
